import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import CareersRoll from "../components/CareersRoll";

export default class CareersPage extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>Careers | CVision AI</title>
        </Helmet>
        <div
          className="hero bg-indigo-800 md:pb-24 md:pt-48 pb-16 pt-24"
        >
          <div className="leading-tight max-w-screen-xl md:px-16 md:text-7xl mx-auto px-6 text-4xl">
            <h1 className="font-serif">{data.markdownRemark.frontmatter.title}</h1>
            {/* <p className="text-indigo-600 w-11/12">{data.markdownRemark.frontmatter.heading}</p> */}
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="max-w-screen-xl md:pb-40 md:pt-20 md:px-16 mx-auto pb-16 pt-4 px-8">
            <CareersRoll />
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
      frontmatter {
        title
        heading
      }
    }
  }
`;
